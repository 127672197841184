/* Pages */
.page-container {
  margin-left: 150px;
  margin-right: 150px;

  h1 {
    font-weight: 600;
    margin-bottom: 50px;
  }
}
