$base-spacer: 5px;

.spacer-0 { margin: 0; }
.spacer-1 { margin: $base-spacer * 1; }
.spacer-2 { margin: $base-spacer * 2; }
.spacer-3 { margin: $base-spacer * 3; }
.spacer-4 { margin: $base-spacer * 4; }
.spacer-5 { margin: $base-spacer * 5; }
.spacer-6 { margin: $base-spacer * 6; }
.spacer-7 { margin: $base-spacer * 7; }
.spacer-8 { margin: $base-spacer * 8; }
.spacer-9 { margin: $base-spacer * 9; }
.spacer-10 { margin: $base-spacer * 10; }
.spacer-11 { margin: $base-spacer * 11; }
.spacer-12 { margin: $base-spacer * 12; }
.spacer-13 { margin: $base-spacer * 13; }
.spacer-14 { margin: $base-spacer * 14; }
.spacer-15 { margin: $base-spacer * 15; }
.spacer-20 { margin: $base-spacer * 20; }

.spacer-bottom-0 { margin-bottom: 0; }
.spacer-bottom-1 { margin-bottom: $base-spacer * 1; }
.spacer-bottom-2 { margin-bottom: $base-spacer * 2; }
.spacer-bottom-3 { margin-bottom: $base-spacer * 3; }
.spacer-bottom-4 { margin-bottom: $base-spacer * 4; }
.spacer-bottom-5 { margin-bottom: $base-spacer * 5; }
.spacer-bottom-6 { margin-bottom: $base-spacer * 6; }
.spacer-bottom-7 { margin-bottom: $base-spacer * 7; }
.spacer-bottom-8 { margin-bottom: $base-spacer * 8; }
.spacer-bottom-9 { margin-bottom: $base-spacer * 9; }
.spacer-bottom-10 { margin-bottom: $base-spacer * 10; }
.spacer-bottom-11 { margin-bottom: $base-spacer * 11; }
.spacer-bottom-12 { margin-bottom: $base-spacer * 12; }
.spacer-bottom-13 { margin-bottom: $base-spacer * 13; }
.spacer-bottom-14 { margin-bottom: $base-spacer * 14; }
.spacer-bottom-15 { margin-bottom: $base-spacer * 15; }
.spacer-bottom-20 { margin-bottom: $base-spacer * 20; }

.spacer-left-0 { margin-left: 0; }
.spacer-left-1 { margin-left: $base-spacer * 1; }
.spacer-left-2 { margin-left: $base-spacer * 2; }
.spacer-left-3 { margin-left: $base-spacer * 3; }
.spacer-left-4 { margin-left: $base-spacer * 4; }
.spacer-left-5 { margin-left: $base-spacer * 5; }
.spacer-left-6 { margin-left: $base-spacer * 6; }
.spacer-left-7 { margin-left: $base-spacer * 7; }
.spacer-left-8 { margin-left: $base-spacer * 8; }
.spacer-left-9 { margin-left: $base-spacer * 9; }
.spacer-left-10 { margin-left: $base-spacer * 10; }
.spacer-left-11 { margin-left: $base-spacer * 11; }
.spacer-left-12 { margin-left: $base-spacer * 12; }
.spacer-left-13 { margin-left: $base-spacer * 13; }
.spacer-left-14 { margin-left: $base-spacer * 14; }
.spacer-left-15 { margin-left: $base-spacer * 15; }
.spacer-left-20 { margin-left: $base-spacer * 20; }

.spacer-right-0 { margin-right: 0; }
.spacer-right-1 { margin-right: $base-spacer * 1; }
.spacer-right-2 { margin-right: $base-spacer * 2; }
.spacer-right-3 { margin-right: $base-spacer * 3; }
.spacer-right-4 { margin-right: $base-spacer * 4; }
.spacer-right-5 { margin-right: $base-spacer * 5; }
.spacer-right-6 { margin-right: $base-spacer * 6; }
.spacer-right-7 { margin-right: $base-spacer * 7; }
.spacer-right-8 { margin-right: $base-spacer * 8; }
.spacer-right-9 { margin-right: $base-spacer * 9; }
.spacer-right-10 { margin-right: $base-spacer * 10; }
.spacer-right-11 { margin-right: $base-spacer * 11; }
.spacer-right-12 { margin-right: $base-spacer * 12; }
.spacer-right-13 { margin-right: $base-spacer * 13; }
.spacer-right-14 { margin-right: $base-spacer * 14; }
.spacer-right-15 { margin-right: $base-spacer * 15; }
.spacer-right-20 { margin-right: $base-spacer * 20; }

.spacer-top-0 { margin-top: 0; }
.spacer-top-1 { margin-top: $base-spacer * 1; }
.spacer-top-2 { margin-top: $base-spacer * 2; }
.spacer-top-3 { margin-top: $base-spacer * 3; }
.spacer-top-4 { margin-top: $base-spacer * 4; }
.spacer-top-5 { margin-top: $base-spacer * 5; }
.spacer-top-6 { margin-top: $base-spacer * 6; }
.spacer-top-7 { margin-top: $base-spacer * 7; }
.spacer-top-8 { margin-top: $base-spacer * 8; }
.spacer-top-9 { margin-top: $base-spacer * 9; }
.spacer-top-10 { margin-top: $base-spacer * 10; }
.spacer-top-11 { margin-top: $base-spacer * 11; }
.spacer-top-12 { margin-top: $base-spacer * 12; }
.spacer-top-13 { margin-top: $base-spacer * 13; }
.spacer-top-14 { margin-top: $base-spacer * 14; }
.spacer-top-15 { margin-top: $base-spacer * 15; }
.spacer-top-20 { margin-top: $base-spacer * 20; }

.spacer-horizontal-0 { margin-left: 0; margin-right: 0; }
.spacer-horizontal-1 { margin-left: $base-spacer * 1; margin-right: $base-spacer * 1; }
.spacer-horizontal-2 { margin-left: $base-spacer * 2; margin-right: $base-spacer * 2; }
.spacer-horizontal-3 { margin-left: $base-spacer * 3; margin-right: $base-spacer * 3; }
.spacer-horizontal-4 { margin-left: $base-spacer * 4; margin-right: $base-spacer * 4; }
.spacer-horizontal-5 { margin-left: $base-spacer * 5; margin-right: $base-spacer * 5; }
.spacer-horizontal-6 { margin-left: $base-spacer * 6; margin-right: $base-spacer * 6; }
.spacer-horizontal-7 { margin-left: $base-spacer * 7; margin-right: $base-spacer * 7; }
.spacer-horizontal-8 { margin-left: $base-spacer * 8; margin-right: $base-spacer * 8; }
.spacer-horizontal-9 { margin-left: $base-spacer * 9; margin-right: $base-spacer * 9; }
.spacer-horizontal-10 { margin-left: $base-spacer * 10; margin-right: $base-spacer * 10; }
.spacer-horizontal-15 { margin-left: $base-spacer * 15; margin-right: $base-spacer * 15; }
.spacer-horizontal-20 { margin-left: $base-spacer * 20; padding-right: $base-spacer * 20; }

.spacer-vertical-0 { margin-top: 0; margin-bottom: 0; }
.spacer-vertical-1 { margin-top: $base-spacer * 1; margin-bottom: $base-spacer * 1; }
.spacer-vertical-2 { margin-top: $base-spacer * 2; margin-bottom: $base-spacer * 2; }
.spacer-vertical-3 { margin-top: $base-spacer * 3; margin-bottom: $base-spacer * 3; }
.spacer-vertical-4 { margin-top: $base-spacer * 4; margin-bottom: $base-spacer * 4; }
.spacer-vertical-5 { margin-top: $base-spacer * 5; margin-bottom: $base-spacer * 5; }
.spacer-vertical-6 { margin-top: $base-spacer * 6; margin-bottom: $base-spacer * 6; }
.spacer-vertical-7 { margin-top: $base-spacer * 7; margin-bottom: $base-spacer * 7; }
.spacer-vertical-8 { margin-top: $base-spacer * 8; margin-bottom: $base-spacer * 8; }
.spacer-vertical-9 { margin-top: $base-spacer * 9; margin-bottom: $base-spacer * 9; }
.spacer-vertical-10 { margin-top: $base-spacer * 10; margin-bottom: $base-spacer * 10; }
.spacer-vertical-15 { margin-top: $base-spacer * 15; margin-bottom: $base-spacer * 15; }
.spacer-vertical-20 { margin-top: $base-spacer * 20; margin-bottom: $base-spacer * 20; }
