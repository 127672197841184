$base-padder: 5px;

.padder-0 { padding: 0; }
.padder-1 { padding: $base-padder * 1; }
.padder-2 { padding: $base-padder * 2; }
.padder-3 { padding: $base-padder * 3; }
.padder-4 { padding: $base-padder * 4; }
.padder-5 { padding: $base-padder * 5; }
.padder-6 { padding: $base-padder * 6; }
.padder-7 { padding: $base-padder * 7; }
.padder-8 { padding: $base-padder * 8; }
.padder-9 { padding: $base-padder * 9; }
.padder-10 { padding: $base-padder * 10; }
.padder-15 { padding: $base-padder * 15; }
.padder-20 { padding: $base-padder * 20; }

.padder-bottom-0 { padding-bottom: 0; }
.padder-bottom-1 { padding-bottom: $base-padder * 1; }
.padder-bottom-2 { padding-bottom: $base-padder * 2; }
.padder-bottom-3 { padding-bottom: $base-padder * 3; }
.padder-bottom-4 { padding-bottom: $base-padder * 4; }
.padder-bottom-5 { padding-bottom: $base-padder * 5; }
.padder-bottom-6 { padding-bottom: $base-padder * 6; }
.padder-bottom-7 { padding-bottom: $base-padder * 7; }
.padder-bottom-8 { padding-bottom: $base-padder * 8; }
.padder-bottom-9 { padding-bottom: $base-padder * 9; }
.padder-bottom-10 { padding-bottom: $base-padder * 10; }
.padder-bottom-15 { padding-bottom: $base-padder * 15; }
.padder-bottom-20 { padding-bottom: $base-padder * 20; }

.padder-left-0 { padding-left: 0; }
.padder-left-1 { padding-left: $base-padder * 1; }
.padder-left-2 { padding-left: $base-padder * 2; }
.padder-left-3 { padding-left: $base-padder * 3; }
.padder-left-4 { padding-left: $base-padder * 4; }
.padder-left-5 { padding-left: $base-padder * 5; }
.padder-left-6 { padding-left: $base-padder * 6; }
.padder-left-7 { padding-left: $base-padder * 7; }
.padder-left-8 { padding-left: $base-padder * 8; }
.padder-left-9 { padding-left: $base-padder * 9; }
.padder-left-10 { padding-left: $base-padder * 10; }

.padder-right-0 { padding-right: 0; }
.padder-right-1 { padding-right: $base-padder * 1; }
.padder-right-2 { padding-right: $base-padder * 2; }
.padder-right-3 { padding-right: $base-padder * 3; }
.padder-right-4 { padding-right: $base-padder * 4; }
.padder-right-5 { padding-right: $base-padder * 5; }
.padder-right-6 { padding-right: $base-padder * 6; }
.padder-right-7 { padding-right: $base-padder * 7; }
.padder-right-8 { padding-right: $base-padder * 8; }
.padder-right-9 { padding-right: $base-padder * 9; }
.padder-right-10 { padding-right: $base-padder * 10; }

.padder-top-0 { padding-top: 0; }
.padder-top-1 { padding-top: $base-padder * 1; }
.padder-top-2 { padding-top: $base-padder * 2; }
.padder-top-3 { padding-top: $base-padder * 3; }
.padder-top-4 { padding-top: $base-padder * 4; }
.padder-top-5 { padding-top: $base-padder * 5; }
.padder-top-6 { padding-top: $base-padder * 6; }
.padder-top-7 { padding-top: $base-padder * 7; }
.padder-top-8 { padding-top: $base-padder * 8; }
.padder-top-9 { padding-top: $base-padder * 9; }
.padder-top-10 { padding-top: $base-padder * 10; }
.padder-top-15 { padding-top: $base-padder * 15; }
.padder-top-20 { padding-top: $base-padder * 20; }

.padder-vertical-0 { padding-top: 0; padding-bottom: 0; }
.padder-vertical-1 { padding-top: $base-padder * 1; padding-bottom: $base-padder * 1; }
.padder-vertical-2 { padding-top: $base-padder * 2; padding-bottom: $base-padder * 2; }
.padder-vertical-3 { padding-top: $base-padder * 3; padding-bottom: $base-padder * 3; }
.padder-vertical-4 { padding-top: $base-padder * 4; padding-bottom: $base-padder * 4; }
.padder-vertical-5 { padding-top: $base-padder * 5; padding-bottom: $base-padder * 5; }
.padder-vertical-6 { padding-top: $base-padder * 6; padding-bottom: $base-padder * 6; }
.padder-vertical-7 { padding-top: $base-padder * 7; padding-bottom: $base-padder * 7; }
.padder-vertical-8 { padding-top: $base-padder * 8; padding-bottom: $base-padder * 8; }
.padder-vertical-9 { padding-top: $base-padder * 9; padding-bottom: $base-padder * 9; }
.padder-vertical-10 { padding-top: $base-padder * 10; padding-bottom: $base-padder * 10; }
.padder-vertical-15 { padding-top: $base-padder * 15; padding-bottom: $base-padder * 15; }
.padder-vertical-20 { padding-top: $base-padder * 20; padding-bottom: $base-padder * 20; }

.padder-horizontal-0 { padding-left: 0; padding-right: 0; }
.padder-horizontal-1 { padding-left: $base-padder * 1; padding-right: $base-padder * 1; }
.padder-horizontal-2 { padding-left: $base-padder * 2; padding-right: $base-padder * 2; }
.padder-horizontal-3 { padding-left: $base-padder * 3; padding-right: $base-padder * 3; }
.padder-horizontal-4 { padding-left: $base-padder * 4; padding-right: $base-padder * 4; }
.padder-horizontal-5 { padding-left: $base-padder * 5; padding-right: $base-padder * 5; }
.padder-horizontal-6 { padding-left: $base-padder * 6; padding-right: $base-padder * 6; }
.padder-horizontal-7 { padding-left: $base-padder * 7; padding-right: $base-padder * 7; }
.padder-horizontal-8 { padding-left: $base-padder * 8; padding-right: $base-padder * 8; }
.padder-horizontal-9 { padding-left: $base-padder * 9; padding-right: $base-padder * 9; }
.padder-horizontal-10 { padding-left: $base-padder * 10; padding-right: $base-padder * 10; }
.padder-horizontal-15 { padding-left: $base-padder * 15; padding-right: $base-padder * 15; }
.padder-horizontal-20 { padding-left: $base-padder * 20; padding-right: $base-padder * 20; }
