/*
 * Blog index page
*/

.posts__container {
  .post__content {
    font-family: "Mercury Text", Georgia, "Times New Roman", sans-serif;
  }

  time {
    color: $gray;
  }

  h2 {
    margin-top: .5rem;
  }

  .link__read_more {
    margin-bottom: 2rem;
  }
}

/*
 * Blog post page
*/
.post__container {
  font-size: 1.5rem;
  p { font-size: 1.5rem; }

  article {
    h1, h2, h3 { margin-top: 45px; }
    h2 { font-size: 2rem; }
  }

  .post__content .drop-cap > p:first-of-type:first-letter {
    color: red;
    float: left;
    font-size: 4em;
    line-height: 4.3rem;
    margin: 0 10px 0 0;
  }

  .post__meta {
    color: $gray;
  }

  .photo__left {
    float: left;
  }

  .photo__photo {
    float: right;
  }

  .rl {
    text-decoration: none;
  }
}

.post__container,
.posts__container {
  .post__content {
    font-family: "Mercury Text", Georgia, "Times New Roman", sans-serif;
  }
}

.orbit-bullets button {
  background-color: $gray;
}

.orbit-bullets button.is-active {
  background-color: $purple;
}
