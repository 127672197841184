/*
* Dark Mode
*/

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(35, 34, 39);
    color: rgb(220, 220, 220);
  }

  body,
  .callout,
  p,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(220, 220, 220);
  }

  a,
  a:visited,
  a:active {
    &:not(.button) {
      color: rgb(220, 220, 220);
    }
  }

  #logo g {
    fill: rgb(220, 220, 220);
  }

  /* Nav links */
  #primary-nav {
    a,
    a:visited,
    a:active {
      color: rgb(200, 200, 200);
    }
  }

  /* Homepage text */
  #hero h2 {
    color: #dcdcdc;
  }

  .download-source-group {
    span, a {
      color: $dark-mode-text-muted-color;

      &:link {
        color: $dark-mode-text-muted-color;
      }
    }
  }

  #footer {
    background-color: rgb(50, 50, 50);

    a, a:visited, a:active {
      color: $dark-mode-text-muted-color;
    }

    h4 {
      color: $dark-mode-text-muted-color;
    }
  }
}

body.dark-mode {
  background-color: rgb(35, 35, 35);
  color: rgb(220, 220, 220);

  .callout,
  p,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(220, 220, 220);
  }

  a,
  a:visited,
  a:active {
    &:not(.button) {
      color: rgb(220, 220, 220);
    }
  }

  #logo g {
    fill: rgb(220, 220, 220);
  }

  /* Nav links */
  #primary-nav {
    a,
    a:visited,
    a:active {
      color: rgb(200, 200, 200);
    }
  }

  /* Homepage text */
  #hero h2 {
    color: #dcdcdc;
  }

  .download-source-group {
    span, a {
      color: $dark-mode-text-muted-color;

      &:link {
        color: $dark-mode-text-muted-color;
      }
    }
  }

  #footer {
    background-color: rgb(50, 50, 50);

    a, a:visited, a:active {
      color: $dark-mode-text-muted-color;
    }

    h4 {
      color: $dark-mode-text-muted-color;
    }
  }
}

/*
* End Dark Mode
*/

/*
* Light Mode
*/

@media (prefers-color-scheme: light) {
  body {
    background-color: rgb(255, 255, 255);
  }

  body,
  .callout,
  p,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(20, 20, 20);
  }

  a,
  a:visited,
  a:active {
    &:not(.button) {
      color: $link-color;
    }
  }

  #logo g {
    fill: rgb(20, 20, 20);
  }

  #primary-nav {
    a,
    a:visited,
    a:active {
      color: #5f5e5f;
    }
  }

  /* Homepage text */
  #hero h2 {
    color: $text-kinda-muted-color;
  }

  .download-source-group {
    span, a {
      color: $light-mode-text-muted-color;

      &:link {
        color: $light-mode-text-muted-color;
      }
    }
  }

  #footer {
    background-color: hsl(0, 0%, 92%);

    a, a:visited, a:active {
      color: $light-mode-text-muted-color;
    }

    h4 {
      color: $light-mode-text-muted-color;
    }
  }
}

body.light-mode {
  background-color: rgb(255, 255, 255);

  .callout,
  p,
  span,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(20, 20, 20);
  }

  a,
  a:visited,
  a:active {
    &:not(.button) {
      color: $link-color;
    }
  }

  #logo g {
    fill: rgb(20, 20, 20);
  }

  #primary-nav {
    a,
    a:visited,
    a:active {
      color: #5f5e5f;
    }
  }

  /* Homepage text */
  #hero h2 {
    color: $text-kinda-muted-color;
  }

  .download-source-group {
    span, a {
      color: $light-mode-text-muted-color;

      &:link {
        color: $light-mode-text-muted-color;
      }
    }
  }

  #footer {
    background-color: hsl(0, 0%, 92%);

    a, a:visited, a:active {
      color: $light-mode-text-muted-color;
    }

    h4 {
      color: $light-mode-text-muted-color;
    }
  }
}

/*
 * End Light Mode
*/

#header {
  margin-top: 50px;
  margin-bottom: 50px;
}

#header svg {
  width: 320px;
}

#primary-nav {
  font-size: 1.5rem;

  a,
  a:visited,
  a:active {
    text-decoration: none;
  }

  li {
    margin: 0 10px;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.button {
  background-color: $red;
  color: #fff;

  &:hover {
    background-color: darken($red, 15%);
  }
}

hr {
  border-bottom: 1px solid #808080;
}

#footer {
  a, a:visited, a:active {
    text-decoration: underline;
  }

  .footer-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    max-width: 62.5rem;
    margin: 0 auto;
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
  }
}


/* Helpers */
ul.inline li {
  display: inline-block;
}

.text-right { text-align: right; }

.pull-right { float: right; }
