$light-gray: #c5c8c6;
$gray: #969896;
$red: #cc6666;
$orange: #de935f;
$yellow: #f0c674;
$green: #b5bd68;
$aqua: #8abeb7;
$blue: #81a2be;
$purple: #b294bb;

$active-nav-color: $purple;
$link-color: darken(#000, 20%);

/* Light mode */
$text-kinda-muted-color: hsla(300, 0%, 17%, 0.75);
$light-mode-text-muted-color: #4D4C4D;
$text-very-muted-color: #9fa0a0;

/* Dark mode */
$dark-mode-text-muted-color: #C2C1C2;

$default-text-color: hsla(0, 0%, 15%, 0.98);

/*
  Orbit carousel button
*/
.orbit .orbit-bullets button.is-active {
  background-color: $red;
}
