#hero {
  h2 { font-size: 2rem; }
  font-weight: 400;
}

img.hero {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  border-radius: 100%;
  width: 50%;
}
